<template>  
  <img src="../assets/images/slash.jpg" alt="snow field" >
  <div class="content">
    <SignUpForm/>
  </div>
</template>

<script>
import LogoBar from '../components/LogoBar.vue';
import SignUpForm from '../components/newSignUp.vue'

export default {
  components: {
    SignUpForm,
    LogoBar
  },
  methods: {
    returnHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-left: 12px;
  padding-right: 12px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
  }
}
</style>  