<template>
  <div class="header">
    <img src="../assets/logo.png" alt="s2s logo" height="120" width="150">
  </div>
</template>

<script>
export default {
  name: 'LogoBar',
  components: {
  }
}
</script>

<style scoped>
.header {
  position: sticky;
  top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

</style>