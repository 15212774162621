<template>
  <router-view/>
</template>

<style>

#app {
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  color: black;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Arizona';
  src: url('./assets/Arizona/ABCArizonaMix-Regular-Trial.otf');
}

p {
  font-family: 'Arizona';
}


* {
  padding: 0;
  margin: 0;
}
</style>
