<template>
  <LogoBar/>
  <div class="content">
  
    <div class="column" id="left">
        <h1>SHARPEN YOUR <br> EDGES</h1>
        <p class="description">Elevate your winter with Somewhere2Ski, your personalized ski planner complete with proprietary long-range forecasting capabilities</p>
        <h1>LAUNCHING <br> 09 / 2024</h1>
    </div>

    <div class="column"  id="right">
      <img src="../assets/images/peaks.jpg" alt="mountain" width="100%" object-fit="cover">
      <img src="../assets/images/slash.jpg" alt="skier" width="100%" object-fit="cover">
      <img src="../assets/images/lift.jpg" alt="s2s lift" width="100%" object-fit="cover">
      <img src="../assets/images/snowField.jpg" alt="snow field" width="100%" object-fit="cover">
      <img src="../assets/images/trees.jpg" alt="trees" width="100%" object-fit="cover">
      <button class="signup" @click="goToSignup">I WANT IN</button>
    </div>
  </div>  
</template>

<script>
import LogoBar from '@/components/LogoBar.vue';
export default {
  name: 'HomeView',
  components: {
    LogoBar
  },
  methods: {
    goToSignup() {
      this.$router.push('/signup')
    }
  }
}
</script>

<style  scoped>
.content {
  padding-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.column {
  display: flex;
  flex-direction: column;
  text-align: left;
}

#left {
  grid-template-columns: 1 / 2;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10%;
  padding-right: 5%;
  gap: 30px;
}

#right {
  grid-template-columns: 2 / 3;
  align-items: center;
}
.signup {
  position: fixed;
  bottom: 20px;
  background-color: transparent;
  color: black;
  padding: 15px;
  border: none;
  border-radius: 60px;
  font-size: calc(6vw + 4px);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 0 0 2px black;
}


.signup:hover {
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

h1 {
  font-size: calc(2.5vw + 14px);
  letter-spacing: -1.5px;
  line-height: .9;
}

.description {
  font-size: calc(.5vw + 12px);
}

</style>