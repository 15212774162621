<script>
  export default {
    data() {
      return {
        email: '',
        submitted: false
      }
    },
    methods: {
      submitForm() {
        fetch('https://api.sendgrid.com/v3/marketing/contacts', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.VUE_APP_SENDGRID_API_KEY
          },
          body: JSON.stringify({
          "contacts" : [{ "email": this.email }],
          })
        }).then(response => {
          if (!response.ok) {
            throw new Error('Error submitting email to mailing list')
          }
          this.submitted = true;
          console.log(`User added to Sendgrid: ${this.email}`)
          // this.$router.push('/')
        }).catch(error => {
          console.error(error)
        })
      },
      returnHome() {
        this.$router.push('/')
      }
    }
  }
</script>

<template>
  <div class="container">
    <img src="../assets/logo.png" alt="s2s logo" height="120" width="150" @click="returnHome" >
    <div class="body">
      <h1>SHARPEN <br> YOUR <br> EDGES</h1>
      <p>Get notified when the door to a better winter opens</p>
      <form @submit.prevent="submitForm" id="signUp">
        <label>Email</label>
        <input type="email" :class="{submitted: submitted}" required v-model="email">
      </form>
    </div>
    <div>
      <button v-if="!submitted"  type="submit" form="signUp" >SIGN UP</button>
      <button v-else  class="submitted" @click="returnHome">THANKS!</button>
    </div>
  </div>

</template>



<style scoped>
img:hover {
  cursor: pointer;
}

/* Large screen styles */
.container {
  background-color:rgb(255, 255, 255, 1);
  display: flex;
  width: 500px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap:36px;
  padding: 24px;
}

.body {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 16px;
}

/* Small screen styles */
@media (max-width: 600px) {
  .container {
    width: 80%;
    padding: 12px;
  }
  .body {
    width: 100%;
  }
}

p {
  font-size: 11px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

input {
  padding: 4px;
}

input.submitted {
  color: grey
}

label {
  font-style: italic;
  align-self: flex-start;
  font-size: 11px;
}

button {
  border-color: black;
  color: black;
  background-color: transparent;
  border-radius:60px;
  font-weight: bold;
  font-size: calc(4vw + 14px);
  padding: 10px;
}

button.submitted {
  background-color: black;
  color: white;
}

button:hover {
  background-color: rgb(239, 236, 236);
  cursor: pointer;
}

h1 {
  letter-spacing: -1.5px;
  line-height: .9;
}

img {
  margin-bottom: 25px;
}

</style>